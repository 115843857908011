import { useState, useEffect } from 'react'
import { useWallet, useWalletList, useLovelace } from '@meshsdk/react'
import { Link } from 'react-router-dom';
import './styles.scss';

const ConnectWallet = () => {
	const [selectedWallet, setSelectedWallet] = useState(null);

	const {connect, disconnect, connecting } = useWallet()
	const wallets = useWalletList();

	const lovelace = useLovelace();

	

	useEffect(() => {
		const storedWallet = localStorage.getItem('selectedWallet')
		if (storedWallet) {
			setSelectedWallet(JSON.parse(storedWallet))
			connect(JSON.parse(storedWallet).name)
		}
		
	}, [])

	const handleWalletSelection = (wallet) => {
		localStorage.setItem('selectedWallet', JSON.stringify(wallet));
		setSelectedWallet(wallet);
		connect(wallet.name);
	}

	const handleDisconnect = () => {
		localStorage.removeItem('selectedWallet');
		disconnect();
		setSelectedWallet(null);
	}

	return (
		<>
			<div style={{ width: 'fit-content' }}>
				{selectedWallet ? (
					<button type='button' className='CardanoWallet__StyledMenuButton-sc-13vl5pq-0 fpvlNK mr-wallet-button'>
						<img
							src={selectedWallet.icon}
							alt={selectedWallet.name}
							width='30'
							height='30'
						/>
						{lovelace === null || isNaN(lovelace) ? (
							<span className='walletValue'><span className='getLovelace'><span className="loader-wallet"></span></span></span>
						) : (
							<span className='walletValue'> <span className='cardanoSymbol'>₳</span><span className='getLovelace'>{(parseInt(lovelace) / 1000000).toFixed(2)}</span></span>
						)}

					</button>
				) : (
					<button type='button' className='CardanoWallet__StyledMenuButton-sc-13vl5pq-0 fpvlNK mr-wallet-button'>
						<div className='desktop-text'>
							CONNECT WALLET<svg fill="none" aria-hidden="true" viewBox="0 0 24 24" stroke="currentColor" xmlns="http://www.w3.org/2000/svg" className="ChevronDown__StyledChevronDown-sc-17n3mfh-0 bqaZfE"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path></svg>
						</div>

						<div className='mobile-text'>
							<svg data-v-75dc3046="" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 -2 24 30" className="cc-fill-green w-full h-full"><path data-v-75dc3046="" d="M 20.59375 2 L 17.09375 5.5 L 15.1875 3.59375 C 14.3875 2.79375 13.20625 2.79375 12.40625 3.59375 L 9.90625 6.09375
L 8.90625 5.09375 L 7.5 6.5 L 17.5 16.5 L 18.90625 15.09375 L 17.90625 14.09375
L 20.40625 11.59375 C 21.20625 10.79375 21.20625 9.6125 20.40625 8.8125 L 18.5 6.90625
L 22 3.40625 L 20.59375 2 z M 6.40625 7.59375 L 5 9 L 6 10 L 3.59375 12.40625 C 2.79375 13.20625 2.79375 14.3875 3.59375 15.1875
L 5.5 17.09375 L 2 20.59375 L 3.40625 22 L 6.90625 18.5 L 8.8125 20.40625 C 9.6125 21.20625 10.79375 21.20625 11.59375 20.40625
L 14 18 L 15 19 L 16.40625 17.59375 L 6.40625 7.59375 z"></path></svg>
						</div>
					</button>
				)}
				<div className='CardanoWallet__StyledMenuList-sc-13vl5pq-1 kuiYpC mr-menu-list'>
					{!selectedWallet && !connecting &&
						wallets.map((wallet) => (
							<div className='MenuItem__StyledItem-sc-obisy3-0 EGDgF'
								key={wallet.name}
								onClick={() => handleWalletSelection(wallet)}>
								<img
									src={wallet.icon}
									alt={wallet.name}
									className='MenuItem__StyledIcon-sc-obisy3-1 bsGwOp'
								/>
								<span className='MenuItem__StyledName-sc-obisy3-2 jWnuMx mr-menu-item desktop-text'>{wallet.name}</span>
							</div>
						)
						)}
					{selectedWallet &&
						<div className='MenuItem__StyledItem-sc-obisy3-0 EGDgF' onClick={handleDisconnect}>
							<span className='MenuItem__StyledName-sc-obisy3-2 jWnuMx mr-menu-item desktop-text'>Disconnect</span>
							<span className='MenuItem__StyledName-sc-obisy3-2 jWnuMx mr-menu-item mobile-text'>
								<svg data-v-75dc3046="" xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 24 33" className="cc-fill-green w-full h-full"><path data-v-75dc3046="" d="M 14.8125 2 C 14.3125 2 13.80625 2.19375 13.40625 2.59375 L 11.90625 4.09375 L 10.90625 3.09375 L 9.5 4.5
L 11.8125 6.8125 L 9.1875 9.40625 L 10.59375 10.8125 L 13.1875 8.1875 L 15.8125 10.8125 L 13.1875 13.40625
L 14.59375 14.8125 L 17.1875 12.1875 L 19.5 14.5 L 20.90625 13.09375 L 19.90625 12.09375 L 21.40625 10.59375
C 22.20625 9.79375 22.20625 8.6125 21.40625 7.8125 L 19.5 5.90625 L 22 3.40625 L 20.59375 2 L 18.09375 4.5
L 16.1875 2.59375 C 15.7875 2.19375 15.3125 2 14.8125 2 z M 4.40625 9.59375 L 3 11 L 4 12 L 2.59375 13.40625
C 1.79375 14.20625 1.79375 15.3875 2.59375 16.1875 L 4.5 18.09375 L 2 20.59375 L 3.40625 22 L 5.90625 19.5
L 7.8125 21.40625 C 8.6125 22.20625 9.79375 22.20625 10.59375 21.40625 L 12 20 L 13 21 L 14.40625 19.59375 L 4.40625 9.59375 z"></path></svg>
							</span>
						</div>
					}
				</div>
			</div>

		</>
	)
}

export default ConnectWallet