import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import '../scss/component/_section.scss';
import '../scss/component/_box.scss';
import '../scss/component/_tf-section.scss';
import '../scss/component/_reponsive.scss';
import Banner from '../components/banner';
import kwic_token_icon from '../assets/images/common/tokenimg.png';
import Button from '../components/button/index';
import { useAssets } from '@meshsdk/react';
import { useWallet } from '@meshsdk/react';
import CryptoJS from 'crypto-js';
import { queryStakedNftCounts } from '../api/GraphQL/StakeProject/query.ts';
import { submitSuccess } from '../types/Transactions/SuccessTransaction.d.ts';

function Home() {

    const [stakedNFTsArray, setExcludedAssetNames] = useState([]);
    const [isGettingStakedNfts, setIsGettingStakedNfts] = useState(false);
    const [assetStaked, setStakedNFTs] = useState([]);
    const [signature, setSignature] = useState('');

    // Your state variables
    const [stakedOGKWIC, setStakedOGKWIC] = useState(0);
    const [staked1OF1, setStaked1OF1] = useState(0);
    const [stakedOrdinalRewards, setStakedOrdinalRewards] = useState(0);
    const [stakedMulgakongz, setStakedMulgakongz] = useState(0);
    const [stakedMultiplier, setStakedMultiplier] = useState(0);


    //MAINNET PROJECTID
    const stakeProjectId = "736f0c55-a73c-4552-a3d7-b4070a1510a0";

    // Fetch the data from the API
    const fetchStakedNftCounts = async () => {
        try {
            const result = await queryStakedNftCounts(stakeProjectId);

            result.forEach(({ key, value }) => {
                if (key === "c72d0438330ed1346f4437fcc1c263ea38e933c1124c8d0f2abc6312") {
                    setStakedOGKWIC(value);
                } else if (key === "cbb8d9d7415bbcc6f4c8a832d0437e9f6e7fe4605a1cf110ad903d82") {
                    setStaked1OF1(value);
                } else if (key === "7c15d2a6be43f55000ae6ae0c31b9444a047815a8156df66d363c7a4") {
                    setStakedOrdinalRewards(value);
                } else if (key === "77999d5a1e09f9bdc16393cab713f26345dc0827a9e5134cf0f9da37") {
                    setStakedMulgakongz(value);
                } else if (key === "b98ae818ee0693e61a8e51db1358240eaa441dba60a8c3766e3f5115") {
                    setStakedMultiplier(value);
                }
            });

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    // Check for changes in stored values in localStorage
    useEffect(() => {
        const storedStakedOGKWIC = localStorage.getItem('stakedOGKWIC');
        const storedStaked1OF1 = localStorage.getItem('staked1OF1');
        const storedStakedOrdinalRewards = localStorage.getItem('stakedOrdinalRewards');
        const storedStakedMulgakongz = localStorage.getItem('stakedMulgakongz');
        const storedStakedMultiplier = localStorage.getItem('stakedMultiplier');

        if (
            stakedOGKWIC !== storedStakedOGKWIC ||
            staked1OF1 !== storedStaked1OF1 ||
            stakedOrdinalRewards !== storedStakedOrdinalRewards ||
            stakedMulgakongz !== storedStakedMulgakongz ||
            stakedMultiplier !== storedStakedMultiplier
        ) {
            // If there are changes, update state and localStorage
            setStakedOGKWIC(storedStakedOGKWIC);
            setStaked1OF1(storedStaked1OF1);
            setStakedOrdinalRewards(storedStakedOrdinalRewards);
            setStakedMulgakongz(storedStakedMulgakongz);
            setStakedMultiplier(storedStakedMultiplier);
            fetchStakedNftCounts(); // Fetch new data
        }
    }, []);

    // Update localStorage whenever state changes
    useEffect(() => {
        localStorage.setItem('stakedOGKWIC', stakedOGKWIC);
        localStorage.setItem('staked1OF1', staked1OF1);
        localStorage.setItem('stakedOrdinalRewards', stakedOrdinalRewards);
        localStorage.setItem('stakedMulgakongz', stakedMulgakongz);
        localStorage.setItem('stakedMultiplier', stakedMultiplier);
    }, [stakedOGKWIC, staked1OF1, stakedOrdinalRewards, stakedMulgakongz, stakedMultiplier]);




    return (
        <div className='header-fixed main home3 counter-scroll' data-aos-delay="200" data-aos="fade-up" data-aos-duration="300">

            {<Banner />}
            <section className="home-section" data-aos-delay="200" data-aos="fade-up" data-aos-duration="200">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 center">
                            <h1>$KWIC</h1>
                            <h4>This is the KWIC ecosystem utility token.</h4>
                            <Button title="BUY $KWIC TOKEN" onClick={() => window.location.href = "https://app.vyfi.io/dex?tokenA=lovelace&tokenB=b3ad8b975d24235a43cb2a54d58c717ed9dd11560b4deba2273ffb1d0014df104b574943"} addclass='stake_btn buy_btn' />
                        </div>
                        <div className="col-lg-12 center mt-100">
                            <h2>DASHBOARD STATS</h2>
                        </div>

                        <div className="col-lg-4">
                            <div className="staked-stats">
                                {stakedOGKWIC === null ? (
                                    <span className="loader-stats"></span>
                                ) : (
                                    <h2>{stakedOGKWIC}</h2>
                                )}
                                <h5>KWIC OG</h5>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="staked-stats">
                                {staked1OF1 === null ? (
                                    <span className="loader-stats"></span>
                                ) : (
                                    <h2>{staked1OF1}</h2>
                                )}
                                <h5>KWIC ART 1 OF 1</h5>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="staked-stats">
                                {stakedOrdinalRewards === null ? (
                                    <span className="loader-stats"></span>
                                ) : (
                                    <h2>{stakedOrdinalRewards}</h2>
                                )}

                                <h5>KWIC ORDINAL REWARDS</h5>
                            </div>

                        </div>

                        <div className="col-lg-2"></div>

                        <div className="col-lg-4">
                            <div className="staked-stats">
                                {stakedMulgakongz === null ? (
                                    <span className="loader-stats"></span>
                                ) : (
                                    <h2>{stakedMulgakongz}</h2>
                                )}

                                <h5>MULGAKONGZ</h5>
                            </div>

                        </div>
                        <div className="col-lg-4">
                            <div className="staked-stats">
                                {stakedMultiplier === null ? (
                                    <span className="loader-stats"></span>
                                ) : (
                                    <h2>{stakedMultiplier}</h2>
                                )}

                                <h5>ACTIVE MULTIPLIERS</h5>
                            </div>

                        </div>
                        <div className="col-lg-2"></div>

                        <div className="col-lg-12 mt-100">
                            <h2>STAKING</h2>
                            <h4>Stake your NFTs and let them accumulate $KWIC Tokens for you!</h4><br></br>
                        </div>

                        <div className="col-lg-12 guide-steps">
                            <h4>BASIC STAKING</h4>
                            <ul className='guide'>
                                <li>Simply connect to a wallet.</li>
                                <li>Go to the <span className="steps-highlight">BASIC STAKE</span> Page.</li>
                                <li>Select the NFT's you would like to stake.</li>
                                <li>Click the STAKE button and Sign the Transaction using your wallet.</li>
                                <li>You are all set!</li>
                            </ul>
                        </div>
                        <div className="col-lg-12 guide-steps">
                            <br></br>
                            <h4>WITH MULTIPLIER</h4>
                            <ul className='guide'>
                                <li>You will need an unstaked OG KWIC and an unstaked KWIC - Playable Avatar.</li>
                                <li>Connect your wallet to the KWIC Dashboard.</li>
                                <li>Hover your mouse over the <span className="steps-highlight">"STAKING"</span> tab and click <span className="steps-highlight">"STAKE WITH MULTIPLIER"</span>.</li>
                                <li>Select the OG KWIC NFTs you would like to pair with your KWIC - Playable Avatars.</li>
                                <li>Select the KWIC - Playable Avatars you would like to pair with your OG KWIC NFTs.<br></br>
                                <span className="steps-highlight">NOTE: The pairing is based on the sequence of your selection.</span>
                                </li>
                                <li>Click the "STAKE" button and sign the transaction using your wallet.</li>
                                <li>You are all set!</li>
                            </ul>
                        </div>

                        <div className="col-lg-12 mt-100">
                            <h2>STABILISE</h2>
                            <h4>Stabilise your NFTs to claim accumulated $KWIC Tokens</h4>
                        </div>

                        <div className="col-lg-12">
                            <ul className='guide'>
                                <li>Simply connect to a wallet.</li>
                                <li>Go to the <span className="steps-highlight">STABILISE</span> Page.</li>
                                <li>Select the NFT's available to stabilise.</li>
                                <li>Click the STABILISE button and Sign the Transaction using your wallet.</li>
                                <li>Receive your tokens!</li>
                            </ul>
                        </div>

                        {/**            
                        <div className="col-lg-12 mt-100">
                            <h2>MULTIPLIERS</h2>
                            <h4>Browse your Multiplier Assets that you want to Unlink to its pairs.</h4>
                        </div>

                        <div className="col-lg-12">
                            <ul className='guide'>
                                <li>Simply connect to a wallet.</li>
                                <li>Go to the <span className="steps-highlight">MULTIPLIERS</span> Page.</li>
                                <li>Select the NFT's available to unlink.</li>
                                <li>Click the UNLINK button and Sign the Transaction using your wallet.</li>
                            </ul>
                        </div>

                        <div className="col-lg-12 guide-steps">
                            <br></br>
                            <h4>TIPS:</h4>
                            <ul className='guide'>
                                <li>Stabilise your OG first before <span className="steps-highlight">unlinking</span> your Multiplier.</li>
                                <li>Previously paired multiplier requires <span className="steps-highlight">unlinking</span> after stabilising its pair.</li>
                                <li><span className="steps-highlight">Unlink</span> multipliers that doesnt have "Paired with KWIC #" in it.</li>
                                <li><span className="steps-highlight">Unlinking</span> a Multiplier doesn't apply it's multiplier bonus upon stabilising the OG it was previously paired with.</li>
                            </ul>

                            <br></br>
                            <h4>CASES:</h4>
                            <ul className='guide'>
                                <li>If you just bought a Multiplier on the secondary market you can check if it is currently paired with a KWIC OG, if paired you can unlink them at <span className="steps-highlight">MULTIPLIERS</span> Page.</li>
                                <li>If you sold your OG <span className="steps-highlight">unlink</span> the multiplier it is paired with in your wallet, so the new owner can't get the bonus from your multiplier.</li>
                                <li>If your multiplier doesn't show up on <span className="steps-highlight">STAKING WITH MULTIPLIER</span> page, check the <span className="steps-highlight">MULTIPLIER</span> page. If it shows there you need to <span className="steps-highlight">unlink</span> it.</li>
                            </ul>
                        </div>
                                */}

                        <div className="col-lg-12 mt-100">
                            <h2>FORGE</h2>
                            <h4>COMING SOON!</h4>
                        </div>
                        <div className="col-lg-12 mt-100">
                            <h2>SUMMON</h2>
                            <h4>Summon your desired NFT either High Temple Monitor Lizard (HTML) or the REAPER!</h4>
                        </div>

                        <div className="col-lg-12">
                            <ul className='guide'>
                                <li>Simply connect to a wallet.</li>
                                <li>Go to the <span className="steps-highlight">SUMMON</span> Page.</li>
                                <li>Choose the NFT's available to Summon.</li>
                                <li>Select the required asset combinations to enable Sacrifice</li>
                                <li>Click the Sacrifice once it is activated</li>
                                <li>Receive your summoned NFT's after REQUEST FOR MINT period is done.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Home;