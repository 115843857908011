import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { useWallet } from '@meshsdk/react';
  
function Summon  () {
    /**

    const [isLoading, setIsLoading] = useState(true);
    const [htmlCommonCount, setHtmlCommonCount] = useState(0);
    const [htmlCommonSupply, setHtmlCommonSupply] = useState(0);

    const [htmlUncommonCount, setHtmlUncommonCount] = useState(0);
    const [htmlUncommonSupply, setHtmlUncommonSupply] = useState(0);

    const [htmlRareCount, setHtmlRareCount] = useState(0);
    const [htmlRareSupply, setHtmlRareSupply] = useState(0);

    const [htmlLegendaryCount, setHtmlLegendaryCount] = useState(0);
    const [htmlLegendarySupply, setHtmlLegendarySupply] = useState(0);

    const [reaperCommonCount, setReaperCommonCount] = useState(0);
    const [reaperCommonSupply, setReaperCommonSupply] = useState(0);

    const [reaperUncommonCount, setReaperUncommonCount] = useState(0);
    const [reaperUncommonSupply, setReaperUncommonSupply] = useState(0);

    const [reaperRareCount, setReaperRareCount] = useState(0);
    const [reaperRareSupply, setReaperRareSupply] = useState(0);

    const [reaperLegendaryCount, setReaperLegendaryCount] = useState(0);
    const [reaperLegendarySupply, setReaperLegendarySupply] = useState(0);

    const {connected } = useWallet();

    const fetchSupplyCount = async () => {
        try {
            const response = await fetch('https://api.kwicdashboard.io/get-dashboard-supply');
            const data = await response.json();
    
            // Assuming data.data is an array of assets
            const htmlCommon = data.data.find((asset) => asset.asset_name === 'htmlCommon');
            const htmlUncommon = data.data.find((asset) => asset.asset_name === 'htmlUncommon');
            const htmlRare = data.data.find((asset) => asset.asset_name === 'htmlRare');
            const htmlLegendary = data.data.find((asset) => asset.asset_name === 'htmlLegendary');
            const reaperCommon = data.data.find((asset) => asset.asset_name === 'reaperCommon');
            const reaperUncommon = data.data.find((asset) => asset.asset_name === 'reaperUncommon');
            const reaperRare = data.data.find((asset) => asset.asset_name === 'reaperRare');
            const reaperLegendary = data.data.find((asset) => asset.asset_name === 'reaperLegendary');
    
            setHtmlCommonCount(htmlCommon.asset_current_supply);
            setHtmlCommonSupply(htmlCommon.asset_max_supply);

            setHtmlUncommonCount(htmlUncommon.asset_current_supply);
            setHtmlUncommonSupply(htmlUncommon.asset_max_supply);

            setHtmlRareCount(htmlRare.asset_current_supply);
            setHtmlRareSupply(htmlRare.asset_max_supply);

            setHtmlLegendaryCount(htmlLegendary.asset_current_supply);
            setHtmlLegendarySupply(htmlLegendary.asset_max_supply);

            setReaperCommonCount(reaperCommon.asset_current_supply);
            setReaperCommonSupply(reaperCommon.asset_max_supply);

            setReaperUncommonCount(reaperUncommon.asset_current_supply);
            setReaperUncommonSupply(reaperUncommon.asset_max_supply);

            setReaperRareCount(reaperRare.asset_current_supply);
            setReaperRareSupply(reaperRare.asset_max_supply);

            setReaperLegendaryCount(reaperLegendary.asset_current_supply);
            setReaperLegendarySupply(reaperLegendary.asset_max_supply);

            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching supply count:', error);
        }
    };

    const handleSupplyCount = async () => {
        setIsLoading(true);
        fetchSupplyCount();
    };
    
    useEffect(() => {
        handleSupplyCount();
        setTimeout(() => {
            setIsLoading(false);
          }, 5000); 
    }, []);
    */
    
    /**
    return (
        <div className='inner-page'>
            <section className="tf-section project_2">
                {connected ? (
                    <div className="container">
                        <div className="row">
                            {isLoading ? (
                                <div>
                                    <div className="col-lg-12">
                                        <div className="tf-title left mb-400 mt-100">
                                            <div className="row">
                                                <div className="col-lg-12 mt-100 mb-100">
                                                    <h3 className="title center mt-40">
                                                    <span className="loader"></span><br/>
                                                        LOADING
                                                    </h3>
                                                    <h4 className="title center">
                                                        INITIALIZING AVAILABLE SUMMONS
                                                        
                                                    </h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    <div className="col-lg-12">
                                        <div className="tf-title left mb-400 mt-40">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <h3 className="title">
                                                        SELECT SUMMON
                                                    </h3>
                                                    <h4 className="title">
                                                        SELECT THE NFT YOU WANTED TO SUMMON!
                                                    </h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="project_wrapper_2">
                                            <div className="project-box-style8">
                                                {htmlCommonCount === htmlCommonSupply ? (
                                                    <>
                                                     <div className="nft_disabled"></div>
                                                        <div alt="html" className="nft_selection_full disabled">
                                                            <div className="image">
                                                                <div className="nft_multiplier">
                                                                    <h3>$KWIC MULTIPLIER</h3>
                                                                    <h2>400%</h2>
                                                                </div>
                                                                <div className="nft_info">
                                                                    <h4>COMMON</h4>
                                                                    <h5>{htmlCommonCount}/{htmlCommonSupply}</h5>
                                                                </div>
                                                                <img src='https://lime-religious-swordtail-930.mypinata.cloud/ipfs/QmX7Md9SJRuLBbmLcANqbc3nhiajc5yCWLZeAaEXgjtJcv?_gl=1*108pknp*_ga*MTIzMzE0Nzk0OS4xNzAyMTAzNTk0*_ga_5RMPXG14TE*MTcwMjEwMzU5NC4xLjEuMTcwMjEwMzY3Mi41MC4wLjA.' alt="" />
                                                            </div>
                                                        </div>
                                                    </>
                                                ):(
                                                    <Link alt="html" className="nft_selection_full" to='html/common'>
                                                        <div className="image">
                                                            <div className="nft_multiplier">
                                                                <h3>$KWIC MULTIPLIER</h3>
                                                                <h2>400%</h2>
                                                            </div>
                                                            <div className="nft_info">
                                                                <h4>COMMON</h4>
                                                                <h5>{htmlCommonCount}/{htmlCommonSupply}</h5>
                                                            </div>
                                                            <img src='https://lime-religious-swordtail-930.mypinata.cloud/ipfs/QmX7Md9SJRuLBbmLcANqbc3nhiajc5yCWLZeAaEXgjtJcv?_gl=1*108pknp*_ga*MTIzMzE0Nzk0OS4xNzAyMTAzNTk0*_ga_5RMPXG14TE*MTcwMjEwMzU5NC4xLjEuMTcwMjEwMzY3Mi41MC4wLjA.' alt="" />
                                                        </div>
                                                    </Link>
                                                )}
                                            </div>

                                            <div className="project-box-style8">
                                                {htmlUncommonCount === htmlUncommonSupply ? (
                                                    <>
                                                    <div className="nft_disabled"></div>
                                                        <div alt="html" className="nft_selection_full">
                                                        <div className="image">
                                                            <div className="nft_multiplier">
                                                                <h3>$KWIC MULTIPLIER</h3>
                                                                <h2>500%</h2>
                                                            </div>
                                                            <div className="nft_info">
                                                                <h4>UNCOMMON</h4>
                                                                <h5>{htmlUncommonCount}/{htmlUncommonSupply}</h5>
                                                            </div>
                                                            <img src='https://lime-religious-swordtail-930.mypinata.cloud/ipfs/QmY45ntsYCh1ggFcozcoGDr2LLfNjr2Vz3wKjdZDPpnE4Z?_gl=1*1l3yrgx*_ga*MTIzMzE0Nzk0OS4xNzAyMTAzNTk0*_ga_5RMPXG14TE*MTcwMjEwMzU5NC4xLjEuMTcwMjEwMzY2Ni41Ni4wLjA.' alt="" />
                                                        </div>
                                                    </div>
                                                    </>
                                                ):(
                                                    <Link alt="html" className="nft_selection_full" to='html/uncommon'>
                                                    <div className="image">
                                                        <div className="nft_multiplier">
                                                            <h3>$KWIC MULTIPLIER</h3>
                                                            <h2>500%</h2>
                                                        </div>
                                                        <div className="nft_info">
                                                            <h4>UNCOMMON</h4>
                                                            <h5>{htmlUncommonCount}/{htmlUncommonSupply}</h5>
                                                        </div>
                                                        <img src='https://lime-religious-swordtail-930.mypinata.cloud/ipfs/QmY45ntsYCh1ggFcozcoGDr2LLfNjr2Vz3wKjdZDPpnE4Z?_gl=1*1l3yrgx*_ga*MTIzMzE0Nzk0OS4xNzAyMTAzNTk0*_ga_5RMPXG14TE*MTcwMjEwMzU5NC4xLjEuMTcwMjEwMzY2Ni41Ni4wLjA.' alt="" />
                                                    </div>
                                                </Link>
                                                )}
                                            </div>
                                            
                                            <div className="project-box-style8">
                                            {htmlRareCount === htmlRareSupply ? (
                                                <>
                                                    <div className="nft_disabled"></div>
                                                    <div alt="html" className="nft_selection_full">
                                                    <div className="image">
                                                        <div className="nft_multiplier">
                                                            <h3>$KWIC MULTIPLIER</h3>
                                                            <h2>600%</h2>
                                                        </div>
                                                        <div className="nft_info">
                                                            <h4>RARE</h4>
                                                            <h5>{htmlRareCount}/{htmlRareSupply}</h5>
                                                        </div>
                                                        <img src='https://lime-religious-swordtail-930.mypinata.cloud/ipfs/QmXvfiYTA34FP1X1MrxaqbbS6rtPYWndE9VKqMTrc9uQ46?_gl=1*1386zis*_ga*MTIzMzE0Nzk0OS4xNzAyMTAzNTk0*_ga_5RMPXG14TE*MTcwMjEwMzU5NC4xLjEuMTcwMjEwMzY3MC41Mi4wLjA.' alt="" />
                                                    </div>
                                                </div>
                                                </>
                                            ):(
                                                <Link alt="html" className="nft_selection_full" to='html/rare'>
                                                    <div className="image">
                                                        <div className="nft_multiplier">
                                                            <h3>$KWIC MULTIPLIER</h3>
                                                            <h2>600%</h2>
                                                        </div>
                                                        <div className="nft_info">
                                                            <h4>RARE</h4>
                                                            <h5>{htmlRareCount}/{htmlRareSupply}</h5>
                                                        </div>
                                                        <img src='https://lime-religious-swordtail-930.mypinata.cloud/ipfs/QmXvfiYTA34FP1X1MrxaqbbS6rtPYWndE9VKqMTrc9uQ46?_gl=1*1386zis*_ga*MTIzMzE0Nzk0OS4xNzAyMTAzNTk0*_ga_5RMPXG14TE*MTcwMjEwMzU5NC4xLjEuMTcwMjEwMzY3MC41Mi4wLjA.' alt="" />
                                                    </div>
                                                </Link>
                                            )}
                                            </div>

                                            <div className="project-box-style8">
                                                {htmlLegendaryCount === htmlLegendarySupply ? (
                                                    <>
                                                    <div className="nft_disabled"></div>
                                                    <div alt="html" className="nft_selection_full">
                                                        <div className="image">
                                                            <div className="nft_multiplier">
                                                                <h3>$KWIC MULTIPLIER</h3>
                                                                <h2>750%</h2>
                                                            </div>
                                                            <div className="nft_info">
                                                                <h4>LEGENDARY</h4>
                                                                <h5>{htmlLegendaryCount}/{htmlLegendarySupply}</h5>
                                                            </div>
                                                            <img src='https://lime-religious-swordtail-930.mypinata.cloud/ipfs/QmTcLYBbSUx37BhQhVMu97vWCbybtgxtEFeFtrhuDzWPa5?_gl=1*sg2c2d*_ga*MTIzMzE0Nzk0OS4xNzAyMTAzNTk0*_ga_5RMPXG14TE*MTcwMjEwMzU5NC4xLjEuMTcwMjEwMzY2Mi42MC4wLjA.' alt="" />
                                                        </div>
                                                    </div>
                                                    </>
                                                ):(
                                                    <Link alt="html" className="nft_selection_full" to='html/legendary'>
                                                        <div className="image">
                                                            <div className="nft_multiplier">
                                                                <h3>$KWIC MULTIPLIER</h3>
                                                                <h2>750%</h2>
                                                            </div>
                                                            <div className="nft_info">
                                                                <h4>LEGENDARY</h4>
                                                                <h5>{htmlLegendaryCount}/{htmlLegendarySupply}</h5>
                                                            </div>
                                                            <img src='https://lime-religious-swordtail-930.mypinata.cloud/ipfs/QmTcLYBbSUx37BhQhVMu97vWCbybtgxtEFeFtrhuDzWPa5?_gl=1*sg2c2d*_ga*MTIzMzE0Nzk0OS4xNzAyMTAzNTk0*_ga_5RMPXG14TE*MTcwMjEwMzU5NC4xLjEuMTcwMjEwMzY2Mi42MC4wLjA.' alt="" />
                                                        </div>
                                                    </Link>
                                                )}
                                            </div>


                                            <div className="project-box-style8">
                                                {reaperCommonCount === reaperCommonSupply ? (
                                                    <>
                                                        <div className="nft_disabled"></div>
                                                        <div alt="html" className="nft_selection_full" >
                                                        <div className="image">
                                                            <div className="nft_multiplier">
                                                                <h3>$KWIC MULTIPLIER</h3>
                                                                <h2>1500%</h2>
                                                            </div>
                                                            <div className="nft_info">
                                                                <h4>COMMON</h4>
                                                                <h5>{reaperCommonCount}/{reaperCommonSupply}</h5>
                                                            </div>
                                                            <img src='https://lime-religious-swordtail-930.mypinata.cloud/ipfs/bafkreiefsyzvsuzcnlyldun3xauhlh5d7ymdgigjfqgipke6ta27pcpcum?_gl=1*sg2c2d*_ga*MTIzMzE0Nzk0OS4xNzAyMTAzNTk0*_ga_5RMPXG14TE*MTcwMjEwMzU5NC4xLjEuMTcwMjEwMzY2Mi42MC4wLjA.' alt="" />
                                                        </div>
                                                    </div>
                                                    </>
                                                ):(
                                                    <Link alt="html" className="nft_selection_full" to='reaper/common'>
                                                        <div className="image">
                                                            <div className="nft_multiplier">
                                                                <h3>$KWIC MULTIPLIER</h3>
                                                                <h2>1500%</h2>
                                                            </div>
                                                            <div className="nft_info">
                                                                <h4>COMMON</h4>
                                                                <h5>{reaperCommonCount}/{reaperCommonSupply}</h5>
                                                            </div>
                                                            <img src='https://lime-religious-swordtail-930.mypinata.cloud/ipfs/bafkreiefsyzvsuzcnlyldun3xauhlh5d7ymdgigjfqgipke6ta27pcpcum?_gl=1*sg2c2d*_ga*MTIzMzE0Nzk0OS4xNzAyMTAzNTk0*_ga_5RMPXG14TE*MTcwMjEwMzU5NC4xLjEuMTcwMjEwMzY2Mi42MC4wLjA.' alt="" />
                                                        </div>
                                                    </Link>
                                                )}
                                            </div>

                                            <div className="project-box-style8">
                                                {reaperUncommonCount === reaperUncommonSupply ? (
                                                    <>
                                                    <div className="nft_disabled"></div>
                                                    <div alt="reaper" className="nft_selection_full"> 
                                                        <div className="image">
                                                            <div className="nft_multiplier">
                                                                <h3>$KWIC MULTIPLIER</h3>
                                                                <h2>1650%</h2>
                                                            </div>
                                                            <div className="nft_info">
                                                                <h4>UNCOMMON</h4>
                                                                <h5>{reaperUncommonCount}/{reaperUncommonSupply}</h5>
                                                            </div>
                                                            <img src='https://lime-religious-swordtail-930.mypinata.cloud/ipfs/bafkreiccmexhwdaeyrmdprr44v4fmrsdqo7zdv3jdgtusch5busd4vtalq?_gl=1*sg2c2d*_ga*MTIzMzE0Nzk0OS4xNzAyMTAzNTk0*_ga_5RMPXG14TE*MTcwMjEwMzU5NC4xLjEuMTcwMjEwMzY2Mi42MC4wLjA.' alt="" />
                                                        </div>
                                                    </div>
                                                    </>
                                                ):(
                                                    <Link alt="reaper" className="nft_selection_full" to='reaper/uncommon'> 
                                                        <div className="image">
                                                            <div className="nft_multiplier">
                                                                <h3>$KWIC MULTIPLIER</h3>
                                                                <h2>1650%</h2>
                                                            </div>
                                                            <div className="nft_info">
                                                                <h4>UNCOMMON</h4>
                                                                <h5>{reaperUncommonCount}/{reaperUncommonSupply}</h5>
                                                            </div>
                                                            <img src='https://lime-religious-swordtail-930.mypinata.cloud/ipfs/bafkreiccmexhwdaeyrmdprr44v4fmrsdqo7zdv3jdgtusch5busd4vtalq?_gl=1*sg2c2d*_ga*MTIzMzE0Nzk0OS4xNzAyMTAzNTk0*_ga_5RMPXG14TE*MTcwMjEwMzU5NC4xLjEuMTcwMjEwMzY2Mi42MC4wLjA.' alt="" />
                                                        </div>
                                                    </Link>
                                                )}
                                            </div>

                                            <div className="project-box-style8">
                                                {reaperRareCount === reaperRareSupply ? (
                                                    <>
                                                    <div className="nft_disabled"></div>
                                                     <div alt="reaper" className="nft_selection_full"> 
                                                        <div className="image">
                                                            <div className="nft_multiplier">
                                                                <h3>$KWIC MULTIPLIER</h3>
                                                                <h2>1800%</h2>
                                                            </div>
                                                            <div className="nft_info">
                                                                <h4>RARE</h4>
                                                                <h5>{reaperRareCount}/{reaperRareSupply}</h5>
                                                            </div>
                                                            <img src='https://lime-religious-swordtail-930.mypinata.cloud/ipfs/bafkreifmqrmc22ryyoyruo3zqb7btedtiy2qzz2sjnjnaev6htqrsn3lke?_gl=1*sg2c2d*_ga*MTIzMzE0Nzk0OS4xNzAyMTAzNTk0*_ga_5RMPXG14TE*MTcwMjEwMzU5NC4xLjEuMTcwMjEwMzY2Mi42MC4wLjA.' alt="" />
                                                        </div>
                                                    </div>
                                                    </>
                                                ):(
                                                    <Link alt="reaper" className="nft_selection_full" to='reaper/rare'> 
                                                        <div className="image">
                                                            <div className="nft_multiplier">
                                                                <h3>$KWIC MULTIPLIER</h3>
                                                                <h2>1800%</h2>
                                                            </div>
                                                            <div className="nft_info">
                                                                <h4>RARE</h4>
                                                                <h5>{reaperRareCount}/{reaperRareSupply}</h5>
                                                            </div>
                                                            <img src='https://lime-religious-swordtail-930.mypinata.cloud/ipfs/bafkreifmqrmc22ryyoyruo3zqb7btedtiy2qzz2sjnjnaev6htqrsn3lke?_gl=1*sg2c2d*_ga*MTIzMzE0Nzk0OS4xNzAyMTAzNTk0*_ga_5RMPXG14TE*MTcwMjEwMzU5NC4xLjEuMTcwMjEwMzY2Mi42MC4wLjA.' alt="" />
                                                        </div>
                                                    </Link>
                                                )}
                                            </div>

                                            <div className="project-box-style8">
                                            {reaperLegendaryCount === reaperLegendarySupply ? (
                                               <>
                                               <div className="nft_disabled"></div>
                                                 <div alt="reaper" className="nft_selection_full"> 
                                                    <div className="image">
                                                        <div className="nft_multiplier">
                                                            <h3>$KWIC MULTIPLIER</h3>
                                                            <h2>2000%</h2>
                                                        </div>
                                                        <div className="nft_info">
                                                            <h4>LEGENDARY</h4>
                                                            <h5>{reaperLegendaryCount}/{reaperLegendarySupply}</h5>
                                                        </div>
                                                        <img src='https://lime-religious-swordtail-930.mypinata.cloud/ipfs/bafkreifuuuewvybweqrwbu3edk63pipfnxdrb7ctudxvuqrvejwrspeg4a?_gl=1*sg2c2d*_ga*MTIzMzE0Nzk0OS4xNzAyMTAzNTk0*_ga_5RMPXG14TE*MTcwMjEwMzU5NC4xLjEuMTcwMjEwMzY2Mi42MC4wLjA.' alt="" />
                                                    </div>
                                                </div>
                                               </>
                                            ):(
                                                <Link alt="reaper" className="nft_selection_full" to='reaper/legendary'> 
                                                    <div className="image">
                                                        <div className="nft_multiplier">
                                                            <h3>$KWIC MULTIPLIER</h3>
                                                            <h2>2000%</h2>
                                                        </div>
                                                        <div className="nft_info">
                                                            <h4>LEGENDARY</h4>
                                                            <h5>{reaperLegendaryCount}/{reaperLegendarySupply}</h5>
                                                        </div>
                                                        <img src='https://lime-religious-swordtail-930.mypinata.cloud/ipfs/bafkreifuuuewvybweqrwbu3edk63pipfnxdrb7ctudxvuqrvejwrspeg4a?_gl=1*sg2c2d*_ga*MTIzMzE0Nzk0OS4xNzAyMTAzNTk0*_ga_5RMPXG14TE*MTcwMjEwMzU5NC4xLjEuMTcwMjEwMzY2Mi42MC4wLjA.' alt="" />
                                                    </div>
                                                </Link>
                                            )}
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                ) : (
                    <div className="container mb-100">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="project_wrapper_2">
                                    <div className="no-wallet-connected"> <h2>CONNECT TO A WALLET</h2></div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </section>
        </div>
    );
     */

    return (
        <div className='inner-page'>
            <section className="tf-section tf-wallet" data-aos-delay="200" data-aos="fade-up" data-aos-duration="300">
                <div className="container">
                    <div className="row">   
                        <div className="col-lg-12 center">
                            <br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br>
                            <h1>SUMMONS ON GOING</h1>
                            <h2>SACRIFICE HAS ENDED!</h2>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );

}

export default Summon;

