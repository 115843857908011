import Home from "./Home";
import Forge from "./Forge";
import Stake from "./Stake";
import StakeMultiplier from "./StakeMultiplier";
import Stabilise from "./Stabilise";
import Summon from "./Summon";
import Multipliers from "./Multipliers";
import SummonCommonHTML from "./SummonCommonHTML";
import SummonUncommonHTML from "./SummonUncommonHTML";
import SummonRareHTML from "./SummonRareHTML";
import SummonLegendaryHTML from "./SummonLegendaryHTML";
import SummonCommonReaper from "./SummonCommonReaper";
import SummonUncommonReaper from "./SummonUncommonReaper";
import SummonRareReaper from "./SummonRareReaper";
import SummonLegendaryReaper from "./SummonLegendaryReaper";


const routes = [
  
  { path: '/', component: <Home />},
  { path: '/staking', component: <Stake />},
  { path: '/staking_multiplier', component: <StakeMultiplier />},
  { path: '/stabilise', component: <Stabilise />},
  { path: '/multipliers', component: <Multipliers />},
  { path: '/forge', component: <Forge />},
  { path: '/summon', component: <Summon />},
  /**
  { path: '/summon/html/common', component: <SummonCommonHTML />},
  { path: '/summon/html/uncommon', component: <SummonUncommonHTML />},
  { path: '/summon/html/rare', component: <SummonRareHTML />},
  { path: '/summon/html/legendary', component: <SummonLegendaryHTML />},
  { path: '/summon/reaper/common', component: <SummonCommonReaper />},
  { path: '/summon/reaper/uncommon', component: <SummonUncommonReaper />},
  { path: '/summon/reaper/rare', component: <SummonRareReaper />},
  { path: '/summon/reaper/legendary', component: <SummonLegendaryReaper />},
    */
]

export default routes;